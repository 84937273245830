import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { LocationHelperService } from 'src/app/shared/services/location-helper.service';

interface FragmentListener {
  fragmentListener: EventListener,
  boundFragmentListener: EventListener
}

@Injectable({
  providedIn: 'root'
})
export class RvshareAppDetectionService {

  constructor(private locationHelperService: LocationHelperService) { }

  registerFragementListener(listener: FragmentListener) {
    const boundEventListener = listener.fragmentListener.bind(listener);
    window.addEventListener("hashchange", boundEventListener);

    return boundEventListener;
  }

  unregisterFragmentListener(listener: FragmentListener) {
    window.removeEventListener("hashchange", listener.boundFragmentListener);
  }

  isScreenShareApp(): boolean {
    return this.locationHelperService.getSearchParam("rvshare") != null;
  }

  getInvalidCode(): string {
    return this.locationHelperService.getHashParam("invalidCode");
  }

  isSharingViaApp(): boolean {
    return this.locationHelperService.getHashParam("joined") !== null
  }

  getConnectionError(): string {
    return this.locationHelperService.getHashParam("connectionError")
  }
}

angular.module('risevision.apps.services')
  .factory('rvShareAppDetection', downgradeInjectable(RvshareAppDetectionService));
