import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, scan, takeUntil } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { environment } from 'src/environments/environment';

export type ScreenShareMember = {
  memberId: string;
  memberType: string;
  memberDisplayName?: string;
  sharing?: boolean;
};

export type SignalServerData = {
  type: string;
  id?: string;
  joinCode?: string;
  iceConfiguration?: {
    iceServers: { urls: string }[]
  };
  candidate?: {
    candidate: string;
    sdpMLineIndex: number;
    sdpMid: string;
  };
  sdp?: string;
  channel?: any;
  memberId?: string;
  members?: ScreenShareMember[];
  senderId?: string;
};

@Injectable({
  providedIn: 'root'
})
export class ScreenSharingService {
  static readonly MSG_JOINED_CHANNEL = 'joined-channel';
  static readonly MSG_INVALID_CHANNEL = 'invalid-channel';
  static readonly MSG_ICE_CANDIDATE = 'icecandidate';
  static readonly MSG_OFFER = 'offer';
  static readonly MSG_ANSWER = 'answer';
  static readonly MSG_NEW_MEMBER = 'new-member';
  static readonly MSG_MODERATOR_NOT_ALLOWED = 'moderator-not-allowed';
  static readonly MSG_MODERATOR_NOT_AUTHENTICATED = 'moderator-not-authenticated';
  static readonly MSG_MEMBER_DISCONNECTED = 'member-disconnected';
  static readonly MSG_REMOVED_FROM_CHANNEL = 'removed-from-channel';
  static readonly MSG_REMOVE_FROM_CHANNEL = 'remove-from-channel';
  static readonly MSG_INVITE_SHARING = 'invite-sharing';
  static readonly MSG_STOP_SHARING = 'stop-sharing';
  static readonly MSG_CLIENT_STREAM_CLOSED = 'client-stream-closed';
  static readonly MSG_CLIENT_STREAM_PAUSED = 'client-stream-paused';
  static readonly MSG_RESET_CHANNEL = 'reset-channel';
  static readonly MSG_CHANNEL_CLOSED = 'channel-closed';
  static readonly MSG_ERROR = 'error';

  socket: WebSocketSubject<SignalServerData>;
  socketSubscription: Subscription;
  peerConnection: RTCPeerConnection;
  channelError: string;
  joinCodeError: string;
  iceConfiguration: any;
  connecting: boolean = false;
  closing: boolean = false;
  mediaStream: MediaStream;
  joinedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  invitedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  started = 0;
  name = '';
  moderator: boolean;
  memberId: string;
  senderId: string;
  invitedId: string;
  participants: ScreenShareMember[];

  get isModerated(): boolean {
    return this.participants?.find((item) => item.memberType === 'moderator') !== undefined;
  }

  get moderatorCount(): number {
    if (this.participants) {
      return this.participants.filter((item) => item.memberType ==='moderator').length;
    }
    return 0;
  }

  private readonly maxReconnectAttempts = 5; // Adjust this as needed
  private readonly reconnectInterval = 5000; // Adjust this as needed (milliseconds)
  private destroy$ = new Subject<void>();

  constructor(
    private userStateService: UserStateService,
    private webSocketService: WebSocketService,
    private analyticsFactory: AnalyticsFactory
  ) { }

  private filterParticipants(members?: ScreenShareMember[]): void {
    if (members) {
      this.participants = members
        .filter((member) => member.memberType === 'moderator' || member.memberType === 'streamer')
        .sort((a) => a.memberType === 'moderator' ? -1 : 0);
    }
  }

  join(joinCode: string, name: string, moderator?: boolean): void {
    console.log('Joining channel with join code: ', joinCode);
    this.channelError = '';
    this.joinCodeError = '';
    this.name = name;
    this.connecting = true;
    this.closing = false;
    this.moderator = !!moderator;

    this.connectToWebSocket(joinCode, name)
      .pipe(
        takeUntil(this.destroy$),
        retryWhen(errors =>
          errors.pipe(
            delayWhen(() => {
              console.log(`Retrying WebSocket connection in ${this.reconnectInterval}ms...`);
              return timer(this.reconnectInterval);
            }),
            scan((retryCount, err) => {
              if (retryCount >= this.maxReconnectAttempts) {
                throw err; // Throw error to stop retrying
              } else {
                console.log(`WebSocket reconnecting attempt ${retryCount + 1}`);
                return retryCount + 1;
              }
            }, 0), // Initial retry count
            catchError(err => {
              console.error(`WebSocket retry attempts exhausted (${this.maxReconnectAttempts}).`);
              throw err; // Rethrow the error to propagate it to the subscription's error handler
            })
          )
        )
      )
      .subscribe({
        next: (data) => {
          console.log('streamer onmessage', data);

          switch (data.type) {
            case ScreenSharingService.MSG_JOINED_CHANNEL:
              console.log("Joined a new channel", data);
              this.channelError = '';
              this.joinCodeError = '';
              this.iceConfiguration = data.iceConfiguration;
              this.filterParticipants(data.members);

              this.memberId = data.memberId;
              if (data.members) {
                this.joinedSubject.next(ScreenSharingService.MSG_JOINED_CHANNEL);
              } else {
                this.start();
              }
              this.connecting = false;
              break;

            case ScreenSharingService.MSG_INVALID_CHANNEL:
              console.warn('Invalid channel: ', data);
              this.joinCodeError = 'Invalid join code: ' + joinCode + '.';
              this.connecting = false;
              this.reset();
              break;

            case ScreenSharingService.MSG_ICE_CANDIDATE:
              if (data.memberId === this.memberId) {
                this.peerConnection?.addIceCandidate(data.candidate);
              }
              break;

            case ScreenSharingService.MSG_OFFER:
              this.senderId = data.senderId;
              break;

            case ScreenSharingService.MSG_ANSWER:
              console.log('Received answer');
              this.invitedId = '';
              if (this.senderId === this.memberId) {
                this.peerConnection?.setRemoteDescription({
                  type: data.type,
                  sdp: data.sdp ?? ""
                });
              }
              if (this.senderId === this.memberId || this.moderator) {
                this.joinedSubject.next(ScreenSharingService.MSG_ANSWER);
              }
              break;

            case ScreenSharingService.MSG_NEW_MEMBER:
              let streaming = !!this.peerConnection;
              console.log('New member joined', data, 'streaming', streaming);
              if (streaming) {
                console.log('Restarting stream');
                this.closePeerConnection();
                this.stream();
              }
              this.filterParticipants(data.members);
              break;

            case ScreenSharingService.MSG_MODERATOR_NOT_ALLOWED:
              this.channelError = 'This display is not configured for screen share moderation. Please check your display settings.';
              this.joinedSubject.next(ScreenSharingService.MSG_ERROR);
              break;

            case ScreenSharingService.MSG_MODERATOR_NOT_AUTHENTICATED:
              this.channelError = 'Authentication failed. Please log in and try again.';
              this.joinedSubject.next(ScreenSharingService.MSG_ERROR);
              break;

            case ScreenSharingService.MSG_MEMBER_DISCONNECTED:
              this.filterParticipants(data.members);
              break;

            case ScreenSharingService.MSG_REMOVED_FROM_CHANNEL:
              this.channelError = 'A moderator has removed you from the room.';
              this.joinedSubject.next(ScreenSharingService.MSG_ERROR);
              break;

            case ScreenSharingService.MSG_INVITE_SHARING:
              this.invitedSubject.next(true);
              break;

            case ScreenSharingService.MSG_STOP_SHARING:
              this.invitedSubject.next(false);
              break;

            case ScreenSharingService.MSG_CLIENT_STREAM_CLOSED:
              this.closeStream();
              if (data.senderId === this.invitedId) {
                this.invitedId = '';
              }
              break;

            default:
              console.log('Unknown message', data);
          }
        },
        error: (e) => {
          console.log('WebSocket error:', e);
          this.channelError = 'Connection could not be established. Please check your network connection.';
          this.joinedSubject.next(ScreenSharingService.MSG_ERROR);
          this.connecting = false;
          this.reset();
        }
      });
  }

  private closeStream() {
    this.senderId = '';
    this.joinedSubject.next(ScreenSharingService.MSG_CLIENT_STREAM_CLOSED);
  }

  private connectToWebSocket(joinCode: string, name: string): Observable<SignalServerData> {
    return new Observable<SignalServerData>(observer => {
      this.socket = this.webSocketService.webSocket({
        url: `${environment.SCREEN_SHARING_SERVICE_URL}?${
          this.moderator ?
            `displayId=${joinCode}&moderator=true&token=${'Bearer ' + this.userStateService.getAccessToken().access_token}`
          : `joinCode=${joinCode}`}&memberDisplayName=${encodeURIComponent(name)
        }`,
        closeObserver: {
          next: () => {
            // The user who initiated the channel reset does not neeed to see this message, and has already stopped streaming
            if (!this.closing) {
              if (this.mediaStream) {
                this.stop(false);
              }
              this.channelError = 'The session has ended.';
              this.joinedSubject.next(ScreenSharingService.MSG_ERROR);
            }
            this.closing = false;
          }
        }
      }

      );
      this.socketSubscription = this.socket.subscribe({
        next: (data) => observer.next(data),
        error: (err) => observer.error(err),
        complete: () => observer.complete()
      });
    });
  }

  start() {
    const mediaOptions = {
      video: {
        width: { ideal: 1920 },
        height: { ideal: 1080 }
      }
    };
    this.connecting = true;

    navigator.mediaDevices.getDisplayMedia(mediaOptions)
      .then((mediaStream: MediaStream) => {
        this.mediaStream = mediaStream;
        this.senderId = this.memberId;
        this.joinedSubject.next(ScreenSharingService.MSG_ANSWER);

        mediaStream.getTracks()
          .forEach(track => {
            track.addEventListener("ended", (e) => {
              console.log("track removed", e);

              this.socket.next({ type: ScreenSharingService.MSG_CLIENT_STREAM_CLOSED });

              this.closeStream();
              this.closePeerConnection();
            });
          });

          this.stream();
      })
      .catch(e => {
        console.log('getDisplayMedia error: ', e);
        this.joinedSubject.next(ScreenSharingService.MSG_JOINED_CHANNEL);
      })
      .finally(() => {
        this.connecting = false;
        const now = new Date();
        this.started = now.getTime();
        this.analyticsFactory.track('Screen Sharing Started', {
          timestamp: now.toISOString()
        });
      });
  }

  stream() {
    this.peerConnection = new RTCPeerConnection(this.iceConfiguration);
    this.peerConnection.addEventListener(
      "connectionstatechange",
      () => {
        console.log('peerConnection.connectionstatechange', this.peerConnection.connectionState);

        switch (this.peerConnection.connectionState) {
          case "new":
          case "connecting":
          case "connected":
            break;
          case "disconnected":
          case "failed":
            this.channelError = 'The connection was interrupted, please try again.';
            this.closePeerConnection();
            this.reset();
            break;
          case "closed":
            this.closePeerConnection();
            break;
          default:
            break;
        }
      },
      false,
    );

    this.peerConnection.addEventListener(ScreenSharingService.MSG_ICE_CANDIDATE, e => {
      let candidate = null;
      if (e.candidate !== null) {
        candidate = {
          candidate: e.candidate.candidate,
          sdpMid: e.candidate.sdpMid,
          sdpMLineIndex: e.candidate.sdpMLineIndex,
        };
      }
      this.socket.next({ type: ScreenSharingService.MSG_ICE_CANDIDATE, candidate });
    });

    this.mediaStream.getTracks()
      .forEach(track => this.peerConnection.addTrack(track, this.mediaStream));

    this.peerConnection.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
      .then(async offer => {
        await this.peerConnection.setLocalDescription(offer);
        console.log('Created offer, sending...');
        this.socket.next({ type: ScreenSharingService.MSG_OFFER, sdp: offer.sdp });
      });
  }

  remove(memberId: string) {
    if (this.senderId === memberId) {
      this.stopSharing();
    }
    this.socket.next({ type: ScreenSharingService.MSG_REMOVE_FROM_CHANNEL, memberId });
  }

  invite(memberId: string) {
    this.invitedId = memberId;
    this.socket.next({ type: ScreenSharingService.MSG_INVITE_SHARING, memberId });
  }

  stopSharing() {
    this.socket.next({ type: ScreenSharingService.MSG_STOP_SHARING, memberId: this.senderId });
  }

  pause() {
    this.closePeerConnection();
    this.socket?.next({ type: ScreenSharingService.MSG_CLIENT_STREAM_PAUSED });
  }

  stop(signal: boolean) {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.mediaStream = null;
    }
    this.senderId = '';

    this.closing = true;
    this.closePeerConnection();
    if (signal) {
      this.socket?.next({ type: ScreenSharingService.MSG_CLIENT_STREAM_CLOSED });
    }
  }

  leave() {
    this.stop(false);
    this.socketSubscription?.unsubscribe();
    this.socket.complete();
    this.participants = [];
    this.joinedSubject.next(ScreenSharingService.MSG_CHANNEL_CLOSED);
  }

  reset() {
    this.closing = true;
    this.destroy$.next();
    this.destroy$.complete();
    this.leave();
  }

  resetChannel() {
    this.socket.next({ type: ScreenSharingService.MSG_RESET_CHANNEL });
    this.reset();
  }

  closePeerConnection() {
    if (this.peerConnection) {
      try {
        this.peerConnection.close();
      } catch (e) {
        console.error('Error closing peerConnection', e);
      } finally {
        this.peerConnection = null;
        const now = new Date();
        this.analyticsFactory.track('Screen Sharing Ended', {
          timestamp: now.toISOString(),
          duration: Math.round((now.getTime() - this.started) / 1000)
        });
      }
    }
  }
}
