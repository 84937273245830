import { Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { ControlContainer, NgForm, NgModel } from "@angular/forms";
import { Display } from "../../services/display";

@Component({
  selector: "edit-screen-sharing",
  templateUrl: './edit-screen-sharing.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  styleUrls: ["./edit-screen-sharing.component.scss"]
})
export class EditScreenSharingComponent implements OnInit, OnChanges {
  @Input() model: ScreenSharingModel = {};

  bulkEditForm: NgForm;
  @ViewChild('screenSharingEnabled')
  screenSharingEnabled: NgModel;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.bulkEditForm = <NgForm>this.controlContainer;
  }

  ngOnChanges(): void {
    if (this.model.screenSharingEnabled && !this.model.screenSharingMode) {
      this.model.screenSharingMode = 'standard';
      this.model.screenSharingStandard = true;
    }
  }

  screenShareModeChange(mode: string, $event: {checked: boolean}) {
    if (mode === 'moderated') {
      this.model.screenSharingStandard = !$event.checked;
    } else if (mode === 'standard') {
      this.model.screenSharingModerated = !$event.checked;
    }
    this.model.screenSharingMode = this.model.screenSharingStandard
      ? 'standard' : 'moderated';
    this.model.screenSharingPinRequired = false;
  }

  screenSharingChange(checked: boolean) {
    if (checked && !this.model.screenSharingMode) {
      this.model.screenSharingMode = 'standard';
      this.model.screenSharingStandard = true;
    }
  }
}

type ScreenSharingModel = Pick<
  Display,
  | 'screenSharingEnabled'
  | 'screenSharingMode'
  | 'screenSharingPinRequired'
  | 'screenSharingModerated'
  | 'screenSharingStandard'
>
