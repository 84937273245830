import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    iconRegistry.setDefaultFontSetClass('fa');
  }

  registerIcons() {
    this.iconRegistry.addSvgIconLiteral('chevron-right', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18L15 12L9 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('close', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.7474305,30.4420732 C35.6298881,30.3248227 35.5638285,30.1656187 35.5638285,29.9995916 C35.5638285,29.8335644 35.6298881,29.6743604 35.7474305,29.5571099 L58.903005,6.40307058 C60.3671281,4.9368154 60.3654493,2.56122496 58.8992552,1.09704078 C57.4330612,-0.367143388 55.0575697,-0.36546455 53.5934465,1.10079057 L30.4403718,24.2448303 C30.3231262,24.3623776 30.1639289,24.4284399 29.9979086,24.4284399 C29.8318883,24.4284399 29.672691,24.3623776 29.5554454,24.2448303 L6.40237068,1.10079057 C4.93824748,-0.364083948 2.56387516,-0.364643569 1.09906167,1.09954062 C-0.36575182,2.56372482 -0.366311417,4.93819606 1.09781178,6.40307058 L24.2483867,29.5571099 C24.365929,29.6743604 24.4319886,29.8335644 24.4319886,29.9995916 C24.4319886,30.1656187 24.365929,30.3248227 24.2483867,30.4420732 L1.09781178,53.5986124 C0.150690139,54.5462201 -0.218964737,55.9271501 0.128092562,57.2212195 C0.475149861,58.5152889 1.48619308,59.5258978 2.78037201,59.8723595 C4.07455095,60.2188213 5.45524904,59.8485001 6.40237068,58.9008924 L29.5554454,35.7443532 C29.672691,35.626806 29.8318883,35.5607436 29.9979086,35.5607436 C30.1639289,35.5607436 30.3231262,35.626806 30.4403718,35.7443532 L53.5934465,58.9008924 C55.0575697,60.3657669 57.431942,60.3663265 58.8967555,58.9021423 C60.3615689,57.4379582 60.3621285,55.063487 58.8980054,53.5986124 L35.7474305,30.4420732 Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('exit', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.83337 2.66671C3.65656 2.66671 3.48699 2.73695 3.36197 2.86197C3.23695 2.98699 3.16671 3.15656 3.16671 3.33337V12.6667C3.16671 12.8435 3.23695 13.0131 3.36197 13.1381C3.48699 13.2631 3.65656 13.3334 3.83337 13.3334H6.50004C6.86823 13.3334 7.16671 13.6319 7.16671 14C7.16671 14.3682 6.86823 14.6667 6.50004 14.6667H3.83337C3.30294 14.6667 2.79423 14.456 2.41916 14.0809C2.04409 13.7058 1.83337 13.1971 1.83337 12.6667V3.33337C1.83337 2.80294 2.04409 2.29423 2.41916 1.91916C2.79423 1.54409 3.30294 1.33337 3.83337 1.33337H6.50004C6.86823 1.33337 7.16671 1.63185 7.16671 2.00004C7.16671 2.36823 6.86823 2.66671 6.50004 2.66671H3.83337Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6953 4.19526C10.9556 3.93491 11.3777 3.93491 11.6381 4.19526L14.9714 7.5286C15.2318 7.78894 15.2318 8.21106 14.9714 8.4714L11.6381 11.8047C11.3777 12.0651 10.9556 12.0651 10.6953 11.8047C10.4349 11.5444 10.4349 11.1223 10.6953 10.8619L13.5572 8L10.6953 5.13807C10.4349 4.87772 10.4349 4.45561 10.6953 4.19526Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83337 8.00004C5.83337 7.63185 6.13185 7.33337 6.50004 7.33337H14.5C14.8682 7.33337 15.1667 7.63185 15.1667 8.00004C15.1667 8.36823 14.8682 8.66671 14.5 8.66671H6.50004C6.13185 8.66671 5.83337 8.36823 5.83337 8.00004Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('pause', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00004 2.66663H4.33337V13.3333H7.00004V2.66663Z" />
        <path d="M12.3333 2.66663H9.66663V13.3333H12.3333V2.66663Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('play', this.sanitizer.bypassSecurityTrustHtml(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 18 18" id="Button-Play--Streamline-Core.svg" height="20" width="20">
        <desc>Button Play Streamline Icon: https://streamlinehq.com</desc>
        <g id="button-play--button-television-buttons-movies-play-tv-video-controls">
          <path id="Union" fill-rule="evenodd" d="M3.058205714285714 0.021902857142857144c-0.3380114285714286 0 -0.6703657142857143 0.08615714285714285 -0.9657028571428571 0.2502262857142857 -0.30073142857142854 0.1533017142857143 -0.5545714285714286 0.3850239999999999 -0.7345942857142856 0.6707771428571427 -0.18261714285714284 0.28985942857142855 -0.2822902857142857 0.6242365714285714 -0.2881931428571428 0.9667737142857142l-0.0000857142857142857 0V14.1024H1.0695451428571427l0.00017028571428571426 0.009828571428571428c0.005902857142857143 0.3425142857142857 0.105576 0.6769142857142857 0.2881931428571428 0.9667428571428571 0.18002285714285712 0.2858285714285714 0.43386285714285716 0.5174857142857142 0.7345942857142856 0.6708571428571428 0.2953371428571428 0.16399999999999998 0.6276914285714286 0.25017142857142854 0.9657028571428571 0.25017142857142854 0.3428 0 0.679782857142857 -0.08857142857142856 0.97824 -0.25725714285714285 0.006571428571428571 -0.003657142857142857 0.013062857142857142 -0.007542857142857143 0.01947428571428571 -0.011542857142857141l9.862594285714286 -6.099942857142857c0.29908571428571423 -0.15073142857142857 0.5515428571428571 -0.38036571428571425 0.7300571428571428 -0.6643428571428571 0.1841142857142857 -0.2932457142857143 0.2819428571428571 -0.6325142857142857 0.2819428571428571 -0.978822857142857 0 -0.34629714285714286 -0.09782857142857142 -0.6855771428571428 -0.2819428571428571 -0.9788114285714286 -0.17862857142857141 -0.2842742857142857 -0.4315428571428571 -0.5141028571428571 -0.7309714285714285 -0.6648228571428572L4.054297142857142 0.2896742857142857c-0.005885714285714286 -0.0036114285714285714 -0.011839999999999998 -0.007116571428571428 -0.01785142857142857 -0.010513142857142858 -0.2984571428571428 -0.16863862857142856 -0.63544 -0.2572582857142857 -0.97824 -0.2572582857142857Z" clip-rule="evenodd" stroke-width="1"></path>
        </g>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('stop-circle', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3333 8.00004C14.3333 11.6819 11.3486 14.6667 7.66667 14.6667C3.98477 14.6667 1 11.6819 1 8.00004C1 4.31814 3.98477 1.33337 7.66667 1.33337C11.3486 1.33337 14.3333 4.31814 14.3333 8.00004ZM5.66667 6.00004H9.66667V10H5.66667V6.00004Z" />
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('user', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.33317C8.61929 3.33317 7.5 4.45246 7.5 5.83317C7.5 7.21388 8.61929 8.33317 10 8.33317C11.3807 8.33317 12.5 7.21388 12.5 5.83317C12.5 4.45246 11.3807 3.33317 10 3.33317ZM5.83333 5.83317C5.83333 3.53198 7.69881 1.6665 10 1.6665C12.3012 1.6665 14.1667 3.53198 14.1667 5.83317C14.1667 8.13436 12.3012 9.99984 10 9.99984C7.69881 9.99984 5.83333 8.13436 5.83333 5.83317ZM3.72039 12.8869C4.50179 12.1055 5.5616 11.6665 6.66667 11.6665H13.3333C14.4384 11.6665 15.4982 12.1055 16.2796 12.8869C17.061 13.6683 17.5 14.7281 17.5 15.8332V17.4998C17.5 17.9601 17.1269 18.3332 16.6667 18.3332C16.2064 18.3332 15.8333 17.9601 15.8333 17.4998V15.8332C15.8333 15.1701 15.5699 14.5342 15.1011 14.0654C14.6323 13.5966 13.9964 13.3332 13.3333 13.3332H6.66667C6.00363 13.3332 5.36774 13.5966 4.8989 14.0654C4.43006 14.5342 4.16667 15.1701 4.16667 15.8332V17.4998C4.16667 17.9601 3.79357 18.3332 3.33333 18.3332C2.8731 18.3332 2.5 17.9601 2.5 17.4998V15.8332C2.5 14.7281 2.93899 13.6683 3.72039 12.8869Z"/>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('award', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2453_43814)">
          <path d="M12.4999 15.2129C16.3659 15.2129 19.4999 12.0789 19.4999 8.21289C19.4999 4.3469 16.3659 1.21289 12.4999 1.21289C8.63388 1.21289 5.49988 4.3469 5.49988 8.21289C5.49988 12.0789 8.63388 15.2129 12.4999 15.2129Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.70988 14.1028L7.49988 23.2128L12.4999 20.2128L17.4999 23.2128L16.2899 14.0928" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2453_43814">
            <rect width="24" height="24" fill="white" transform="translate(0.499878 0.212891)"/>
          </clipPath>
        </defs>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('alert-triangle', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7899 4.0731L2.31988 18.2131C2.14524 18.5155 2.05284 18.8584 2.05186 19.2076C2.05089 19.5568 2.14137 19.9002 2.3143 20.2036C2.48724 20.507 2.7366 20.7598 3.03758 20.9369C3.33857 21.1141 3.68068 21.2093 4.02988 21.2131H20.9699C21.3191 21.2093 21.6612 21.1141 21.9622 20.9369C22.2632 20.7598 22.5125 20.507 22.6854 20.2036C22.8584 19.9002 22.9489 19.5568 22.9479 19.2076C22.9469 18.8584 22.8545 18.5155 22.6799 18.2131L14.2099 4.0731C14.0316 3.7792 13.7806 3.53621 13.4811 3.36758C13.1815 3.19894 12.8436 3.11035 12.4999 3.11035C12.1561 3.11035 11.8182 3.19894 11.5187 3.36758C11.2192 3.53621 10.9681 3.7792 10.7899 4.0731Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.4999 9.21289V13.2129" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.4999 17.2129H12.5099" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('phone-rotate', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2453_43838)">
          <path d="M6.39006 9.15862L9.44476 6.10392C9.44476 6.10392 10.463 5.08568 11.4812 6.10392L18.6089 13.2316C18.6089 13.2316 19.6271 14.2498 18.6089 15.268L15.5542 18.3227C15.5542 18.3227 14.5359 19.341 13.5177 18.3227L6.39006 11.1951C6.39006 11.1951 5.37182 10.1769 6.39006 9.15862Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.45988 17.2529V21.5729H2.41988" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5.17508 4.29297C4.07937 5.30082 3.20477 6.52511 2.60658 7.88838C2.00839 9.25165 1.69965 10.7242 1.69988 12.213C1.76467 14.1389 2.32987 16.0149 3.33984 17.6561C4.34981 19.2973 5.76974 20.6472 7.45988 21.573" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17.5399 7.17254V2.85254H22.5799" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.8247 20.1325C20.9204 19.1247 21.7951 17.9004 22.3933 16.5372C22.9914 15.1739 23.3002 13.7013 23.2999 12.2125C23.2349 10.2866 22.6696 8.41076 21.6597 6.76959C20.6497 5.12842 19.2299 3.77846 17.5399 2.85254" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2453_43838">
            <rect width="24" height="24" fill="white" transform="translate(0.499878 0.212891)"/>
          </clipPath>
        </defs>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('alarm-clock', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2246_27209)">
          <path d="M3.85986 13.683C3.85986 14.8176 4.08334 15.9411 4.51754 16.9894C4.95174 18.0376 5.58816 18.9901 6.39046 19.7924C7.19276 20.5947 8.14523 21.2311 9.19348 21.6653C10.2417 22.0995 11.3652 22.323 12.4999 22.323C13.6345 22.323 14.758 22.0995 15.8062 21.6653C16.8545 21.2311 17.807 20.5947 18.6093 19.7924C19.4116 18.9901 20.048 18.0376 20.4822 16.9894C20.9164 15.9411 21.1399 14.8176 21.1399 13.683C21.1399 12.5483 20.9164 11.4248 20.4822 10.3766C20.048 9.32833 19.4116 8.37586 18.6093 7.57357C17.807 6.77127 16.8545 6.13485 15.8062 5.70065C14.758 5.26645 13.6345 5.04297 12.4999 5.04297C11.3652 5.04297 10.2417 5.26645 9.19348 5.70065C8.14523 6.13485 7.19276 6.77127 6.39046 7.57357C5.58816 8.37586 4.95174 9.32833 4.51754 10.3766C4.08334 11.4248 3.85986 12.5483 3.85986 13.683Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.73987 23.7627L8.07043 21.1025" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.4999 13.6826H9.24451" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.4999 8.64258V13.6826" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.41992 5.04309L6.01992 2.16309" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.2599 23.7627L16.9293 21.1016" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.5799 5.04309L18.9799 2.16309" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2246_27209">
            <rect width="24" height="24" fill="white" transform="translate(0.499878 0.962891)"/>
          </clipPath>
        </defs>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('power', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-  path="url(#clip0_2246_27223)">
          <path d="M12.4999 2.96289V12.9629" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.8624 7.60039C20.4919 9.22919 21.4999 11.4798 21.4999 13.9658C21.4999 18.9364 17.4704 22.9658 12.4999 22.9658C7.52931 22.9658 3.49988 18.9364 3.49988 13.9658C3.49988 10.7231 5.21478 7.88101 7.78712 6.29688M7.78712 6.29688H1.56238M7.78712 6.29688V12.8379" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2246_27223">
            <rect width="24" height="24" fill="white" transform="translate(0.499878 0.962891)"/>
          </clipPath>
        </defs>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('refresh', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2246_27231)">
          <path d="M23.4999 4.96289V10.9629H17.4999" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1.49988 20.9629V14.9629H7.49988" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.00988 9.96248C4.51705 8.52926 5.37901 7.24788 6.51534 6.2379C7.65168 5.22791 9.02534 4.52224 10.5082 4.18674C11.991 3.85123 13.5346 3.89682 14.9951 4.31925C16.4555 4.74168 17.7851 5.52719 18.8599 6.60248L23.4999 10.9625M1.49988 14.9625L6.13988 19.3225C7.21463 20.3978 8.54425 21.1833 10.0047 21.6057C11.4651 22.0281 13.0088 22.0737 14.4916 21.7382C15.9744 21.4027 17.3481 20.697 18.4844 19.6871C19.6207 18.6771 20.4827 17.3957 20.9899 15.9625" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2246_27231">
            <rect width="24" height="24" fill="white" transform="translate(0.499878 0.962891)"/>
          </clipPath>
        </defs>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('activity', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.4999 12.9629H18.4999L15.4999 21.9629L9.49988 3.96289L6.49988 12.9629H2.49988" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('users', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2246_27165)">
          <path d="M17.5 21.9629V19.9629C17.5 18.902 17.0786 17.8846 16.3284 17.1345C15.5783 16.3843 14.5609 15.9629 13.5 15.9629H5.5C4.43913 15.9629 3.42172 16.3843 2.67157 17.1345C1.92143 17.8846 1.5 18.902 1.5 19.9629V21.9629" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.5 11.9629C11.7091 11.9629 13.5 10.172 13.5 7.96289C13.5 5.75375 11.7091 3.96289 9.5 3.96289C7.29086 3.96289 5.5 5.75375 5.5 7.96289C5.5 10.172 7.29086 11.9629 9.5 11.9629Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M23.5 21.9628V19.9628C23.4993 19.0765 23.2044 18.2155 22.6614 17.5151C22.1184 16.8146 21.3581 16.3143 20.5 16.0928" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.5 4.09277C17.3604 4.31307 18.123 4.81347 18.6676 5.51508C19.2122 6.21669 19.5078 7.0796 19.5078 7.96777C19.5078 8.85594 19.2122 9.71885 18.6676 10.4205C18.123 11.1221 17.3604 11.6225 16.5 11.8428" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2246_27165">
            <rect width="24" height="24" fill="white" transform="translate(0.5 0.962891)"/>
          </clipPath>
        </defs>
      </svg>
    `));
    this.iconRegistry.addSvgIconLiteral('map-pin', this.sanitizer.bypassSecurityTrustHtml(`
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3014_3849)">
          <path d="M21.4999 10.2129C21.4999 17.2129 12.4999 23.2129 12.4999 23.2129C12.4999 23.2129 3.49988 17.2129 3.49988 10.2129C3.49988 7.82594 4.44809 5.53676 6.13592 3.84893C7.82374 2.1611 10.1129 1.21289 12.4999 1.21289C14.8868 1.21289 17.176 2.1611 18.8638 3.84893C20.5517 5.53676 21.4999 7.82594 21.4999 10.2129Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.4999 13.2129C14.1567 13.2129 15.4999 11.8697 15.4999 10.2129C15.4999 8.55604 14.1567 7.21289 12.4999 7.21289C10.843 7.21289 9.49988 8.55604 9.49988 10.2129C9.49988 11.8697 10.843 13.2129 12.4999 13.2129Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_3014_3849">
            <rect width="24" height="24" fill="white" transform="translate(0.499878 0.212891)"/>
          </clipPath>
        </defs>
      </svg>
  `));
  }
}
