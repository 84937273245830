<div id="addStoreProductModal" rvSpinner="product-list-loader" [rvShowSpinner]="products.loadingItems">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()" aria-label="Close this modal">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 id="storeModalTitle" class="modal-title text-center">Add Presentation</h4>
  </div>
  <div class="modal-body u_padding-lg">
    <div class="row">
      <div id="template-filters">
        <div class="border-round">
          <div class="p-3 border-bottom">
            <strong>Filters</strong>
          </div>
          <mat-accordion displayMode="flat" multi="true">
            <mat-expansion-panel class="border-bottom expansion-panel-flush" [disabled]="!datesAvailable" [expanded]="datesAvailable">
              <mat-expansion-panel-header class="p-3 show-focus">Events Calendar</mat-expansion-panel-header>
              <template-calendar [displayed]="datesAvailable" [(selectedDate)]="selectedDate" (selectedDateChange)="doSearch()"></template-calendar>
            </mat-expansion-panel>
            <mat-expansion-panel class="border-bottom" [disabled]="!categoryFilters.templateCategories" [expanded]="preloadFilter">
              <mat-expansion-panel-header class="p-3 show-focus">Category</mat-expansion-panel-header>
              @for (category of categoryFilters.templateCategories; track category) {
                <div>
                  <mat-checkbox #FilterCheckbox id="checkbox-templateCategories-{{ slugify(category.name) }}" (change)="setFilter($event, 'templateCategories', category.name)" [disabled]="showSharedTemplates">
                    {{ category.name }} ({{ category.count }})
                  </mat-checkbox>
                </div>
              }
            </mat-expansion-panel>
            <mat-expansion-panel class="border-bottom u_remove-radius" [disabled]="!categoryFilters.templateContentTypes">
              <mat-expansion-panel-header class="p-3 show-focus">Content</mat-expansion-panel-header>
              @for (contentType of categoryFilters.templateContentTypes; track contentType) {
                <div>
                  <mat-checkbox #FilterCheckbox id="checkbox-templateContentTypes-{{ slugify(contentType.name) }}" (change)="setFilter($event, 'templateContentTypes', contentType.name)" [disabled]="showSharedTemplates">
                    {{ contentType.name }} ({{ contentType.count }})
                  </mat-checkbox>
                </div>
              }
            </mat-expansion-panel>
          </mat-accordion>
          <div class="flex-row left-right-aligner p-3" [ngClass]="{ 'border-bottom': templates.items.list.length }">
            <label for="apple-tv-toggle-button" id="apple-tv-label" class="font-weight-normal mb-0 u_clickable">Apple TV Compatible</label>
            <mat-slide-toggle id="apple-tv-toggle" class="pl-3" [checked]="filterAppleTv" (change)="toggleAppleTv()" [disabled]="showSharedTemplates" ariaLabelledby="apple-tv-label"></mat-slide-toggle>
          </div>
          <div class="flex-row left-right-aligner p-3" [shown]="templates.items.list.length">
            <label for="templates-source-toggle-button" id="shared-templates-label" class="font-weight-normal mb-0 u_clickable">Shared Templates</label>
            <mat-slide-toggle id="templates-source-toggle" class="pl-3" [checked]="showSharedTemplates" (change)="toggleTemplatesSource()" ariaLabelledby="shared-templates-label"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div id="template-grid">
        <div class="u_margin-sm-bottom">
          <search-filter [filterConfig]="filterConfig" [search]="search" [doSearch]="search.doSearch" iconSet="madero" [localSearch]="showSharedTemplates" [focus]="focusSearch"></search-filter>
        </div>
        <section id="productList" class="product-grid" scrollingList>
          <div #TemplatesGrid class="professional-content panel panel-default border-container mb-0">
            <ng-template #BlankPresentation>
              <!-- Template Image -->
              <div class="template-image" uiSref="apps.editor.workspace.artboard" (click)="close()">
                <img loading="lazy" class="img-responsive" src="https://s3.amazonaws.com/Rise-Images/UI/blank-landscape.png" alt="add blank presentation">
                <span class="cta">Use this Template</span>
                <span class="template-fx"></span>
              </div>
              <!-- Template Details -->
              <div class="template-details">
                <div class="template-title">
                  <span>Blank</span>
                </div>
                <div class="template-button">
                  <button class="btn btn-primary btn-block" id="newPresentationButton" uiSref="apps.editor.workspace.artboard" (click)="close()" aria-label="Use this template">
                    <span>Add</span>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template #Presentation let-product>
              <!-- Template Image -->
              <div class="template-image" [ngClass]="{'shared' : !product.imageUrl}" (click)="select(product)">
                @if (product.imageUrl) {
                  <img loading="lazy" [src]="product.imageUrl" alt="{{product.imageAlt || product.name}}">
                }
                @else {
                  <streamline-icon name="iconPresentation" width="100%" height="100%"></streamline-icon>
                }
                @if (product.imageUrl) {
                  <span class="cta">{{ isAlertTemplate(product) ? 'Configure Alerts' : 'Preview this Template' }}</span>
                }
                @else {
                  <span class="cta">Use this Template</span>
                }
                <span class="template-fx"></span>
                <div class="template-tag" [shown]="product.templateTag">{{ product.templateTag }}</div>
              </div>
              <!-- Template Details -->
              <div class="template-details">
                <div class="template-title">
                  <div class="flex-row">
                    <span id="productName-{{slugify(product.name)}}">{{product.name | slice: 0: 65}}</span>
                    @if (requiresUpgrade(product)) {
                      <upgrade-notice (closeEvent)="close()" feature="power_bi" class="ml-2 mt-2"></upgrade-notice>
                    }
                  </div>
                </div>
                <div class="template-button" [ngClass]="{ 'alert-button': isAlertTemplate(product) }">
                  <button class="btn btn-primary btn-block" id="btn-{{slugify(product.name)}}" (click)="add(product)" aria-label="Use this template">
                    <span>{{ isAlertTemplate(product) ? "Configure" : "Add" }}</span>
                  </button>
                </div>
              </div>
            </ng-template>
            @if (!showSharedTemplates) {
              @if (!filteredResults && newTemplates.length) {
                <div>
                  @if (templatesService.upcomingEvents.length) {
                    <div>
                      <h5 class="product-grid-header">Upcoming Events</h5>
                      <ul class="panel-body template-list">
                        @for (template of templatesService.upcomingEvents; track template) {
                          <li id="storeProduct-{{ slugify(template.name) }}" class="template-card gallery-card">
                            <ng-container *ngTemplateOutlet="Presentation; context: { $implicit: template }"></ng-container>
                          </li>
                        }
                      </ul>
                    </div>
                  }
                  <h5 class="product-grid-header">Featured Templates</h5>
                  <ul class="panel-body template-list">
                    @for (template of newTemplates; track template) {
                      <li id="storeProduct-{{ slugify(template.name) }}" class="template-card gallery-card">
                        <ng-container *ngTemplateOutlet="Presentation; context: { $implicit: template }"></ng-container>
                      </li>
                    }
                  </ul>
                  <h5 class="product-grid-header">More Templates</h5>
                </div>
              }
              @if (!products.loadingItems && (filteredResults)) {
                <div>
                  @if (!showSharedTemplates) {
                    <div class="flex-row">
                      <h5 class="product-grid-header">Search Results</h5>
                      <mat-chip-set role="list">
                        @if (selectedDate) {
                          <mat-chip
                            role="listitem"
                            color="accent"
                            highlighted
                            (removed)="clearDate()">
                            {{ templatesService.formatDateName(selectedDate) }}
                            <button matChipRemove aria-label="Remove selected date">
                              <streamline-icon name="close" width="12" height="12"></streamline-icon>
                            </button>
                          </mat-chip>
                        }
                        @if (filterAppleTv) {
                          <mat-chip
                            role="listitem"
                            color="accent"
                            highlighted
                            (removed)="clearAppleTv()">
                            Apple TV
                            <button matChipRemove aria-label="Remove Apple TV filter">
                              <streamline-icon name="close" width="12" height="12"></streamline-icon>
                            </button>
                          </mat-chip>
                        }
                        @for (key of searchFilterKeys; track key) {
                          <span>
                            @for (filter of search.templatesFilter[key]; track filter) {
                              <mat-chip
                                role="listitem"
                                color="accent"
                                highlighted
                                [value]="key + ':' + filter"
                                (removed)="clearFilter($event.chip.value)">
                                {{ filter }}
                                <button matChipRemove [ariaLabel]="'Remove ' + filter + ' filter'">
                                  <streamline-icon name="close" width="12" height="12"></streamline-icon>
                                </button>
                              </mat-chip>
                            }
                          </span>
                        }
                        @if (selectedDate || filterAppleTv || searchFilterKeys.length) {
                          <mat-chip
                            role="listitem"
                            color="accent"
                            highlighted
                            class="u_clickable inverted"
                            (click)="clearAllFilters()">
                            Clear Filters
                          </mat-chip>
                        }
                      </mat-chip-set>
                    </div>
                  }
                </div>
              }
              @if (selectedDate) {
                <div>
                  @for (group of templateGroups; track group) {
                    <div>
                      <h6 class="product-grid-group-header" [ngClass]="{ 'mt-2': group === templateGroups[0] }">{{ group.name }}</h6>
                      <ul class="panel-body template-list">
                        @for (template of group.templates; track template) {
                          <li id="template-{{ slugify( template.name )}}" class="template-card gallery-card">
                            <ng-container *ngTemplateOutlet="Presentation; context: { $implicit: template }"></ng-container>
                          </li>
                        }
                      </ul>
                    </div>
                  }
                  @if (templateGroups.length) {
                    <h5 class="product-grid-header">More Results</h5>
                  }
                </div>
              }

              <ul class="panel-body template-list">
                @for (template of filteredProducts; track template) {
                  <li id="storeProduct-{{ slugify(template.name) }}" class="template-card gallery-card">
                    @if (!template.isBlank) {
                        <div>
                        <ng-container *ngTemplateOutlet="Presentation; context: { $implicit: template }"></ng-container>
                      </div>
                    }
                    @if (template.isBlank) {
                      <ng-template [ngTemplateOutlet]="BlankPresentation"></ng-template>
                    }
                  </li>
                }
              <!-- If no search results -->
                <li class="col-sm-12" [shown]="filteredProducts.length === 0 && search.query && !products.loadingItems">
                  <div class="text-muted text-center u_padding-lg">Your search didn't return any results.</div>
                </li>
                @if ((isEducationCustomer || isIndustrialCustomer) && !products.loadingItems) {
                  <li id="suggestTemplate" class="col-sm-12 text-center">
                    <h3>Can’t find what you’re looking for?</h3>
                    <a href="https://docs.google.com/forms/d/1slHKxGxywkiSakMukDr4dMGLmhzhYhoU0F2BHP8FSd4/viewform" target="_blank">
                      Suggest a Template!
                    </a>
                  </li>
                }
              </ul><!--panel-body-->
            }
            @else {
              <h5 class="product-grid-header">Shared Templates</h5>

              <ul class="panel-body template-list">
                @for (sharedTemplate of filteredSharedTemplates; track sharedTemplate) {
                  <li id="storeProduct-{{ slugify(sharedTemplate.name) }}" class="template-card gallery-card">
                    <div>
                      <ng-container *ngTemplateOutlet="Presentation; context: { $implicit: sharedTemplate }"></ng-container>
                    </div>
                  </li>
                }
                <!-- If no search results -->
                <li class="col-sm-12" [shown]="filteredSharedTemplates.length === 0 && search.query && !templates.loadingItems">
                  <div class="text-muted text-center u_padding-lg">Your search didn't return any results.</div>
                </li>
                <li class="col-sm-12 text-center" [shown]="filteredSharedTemplates.length === 0 && !search.query && !templates.loadingItems">
                  <div class="text-muted text-center u_padding-lg">
                    <span [shown]="sharedTemplatesAvailable">There aren't any shared templates to select</span>
                    <span [shown]="sharedTemplatesAvailable === null">
                      <a href="#" uiSref="apps.purchase.home" (click)="close()">Subscribe now</a> to access Shared Templates
                    </span>
                    <span [shown]="sharedTemplatesAvailable !== null && !sharedTemplatesAvailable">
                      <a href="#" (click)="showUpgradePlanModal()">Upgrade Plan</a> to access Shared Templates
                    </span>
                  </div>
                </li>
              </ul><!--panel-body-->
            }

          </div><!--panel-->
        </section>
      </div>
    </div>
  </div>
</div>
