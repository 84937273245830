import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowLocationService {
  get(): typeof location {
    return window.location;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocationHelperService {

  constructor(
    private windowLocationService: WindowLocationService
  ) { }

  getHost() {
    return this.windowLocationService.get().host;
  }

  getSearchParam(param: string) {
    const search = this.windowLocationService.get().search;
    const urlParams = new URLSearchParams(search);

    return urlParams && urlParams.get(param);
  }

  getHashParam(param: string) {
    const hash = this.windowLocationService.get().hash?.slice(1);
    const params = new URLSearchParams(hash);

    return params && params.get(param)
  }

  _looksLikeIp(addr: string) {
    if (/^([0-9])+\.([0-9])+\.([0-9])+\.([0-9])+$/.test(addr)) {
      return (true);
    }
    return (false);
  }

  getBaseDomain() {
    var result: string;
    if (!result) {
      var hostname = this.getHost();

      if (this._looksLikeIp(hostname)) {
        result = hostname;
      } else {
        var parts = hostname.split('.');
        if (parts.length > 1) {
          // Somehow, cookies don't persist if we set the domain to appspot.com.
          // It requires a sub-domain to be set, ie. rva-test.appspot.com.
          if (parts[parts.length - 2] === 'appspot') {
            result = parts.slice(parts.length - 3).join('.');
          } else {
            result = parts.slice(parts.length - 2).join('.');
          }
        } else {
          //localhost
          result = hostname;
        }
      }

      console.debug('baseDomain', result);
    }
    return result;
  }


}
